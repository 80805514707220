import httpClient from "./http-client";
import authHeader from "./auth-header";
import { AxiosRequestHeaders } from "axios";

const getProjects = async (page = 1, size = 10): Promise<any> => {
  const params = {
    page,
    size,
  };

  const { data } = await httpClient.get("/b-client/v1/project", {
    params,
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

const getProject = async (id: number): Promise<any> => {
  const { data } = await httpClient.get(`/b-client/v1/project/${id}`, {
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

const getProjectParticipant = async (): Promise<any> => {
  const { data } = await httpClient.get("/b-participant/v1/project", {
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

const createProject = async (req: any): Promise<any> => {
  const { data } = await httpClient.post("/b-client/v1/project", req, {
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

const updateProjectStatus = async (
  projectId: number,
  status: string
): Promise<any> => {
  await httpClient.put(
    `/b-client/v1/project/${projectId}/status`,
    { status },
    {
      headers: authHeader() as AxiosRequestHeaders,
    }
  );
};

const validateProject = async (id: number, req: any): Promise<any> => {
  const { data } = await httpClient.post(
    `/b-client/v1/project/${id}/validate`,
    req,
    {
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

const inviteParticipants = async (id: number, req: any): Promise<any> => {
  const { data } = await httpClient.post(
    `/b-client/v1/project/${id}/invite`,
    req,
    {
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

const getProjectTestDetail = async (
  projectId: number,
  testId: number,
  page = 1,
  size = 10
): Promise<any> => {
  const params = {
    page,
    size,
  };

  const { data } = await httpClient.get(
    `/b-client/v1/project/${projectId}/bundle/${testId}`,
    {
      params,
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

const getProjectParticipantDetail = async (
  projectId: number,
  participantId: number,
  page = 1,
  size = 10
): Promise<any> => {
  const params = {
    page,
    size,
  };

  const { data } = await httpClient.get(
    `/b-client/v1/project/${projectId}/participant/${participantId}`,
    {
      params,
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

const resendEmailProjectParticipant = async (
  projectId: number,
  participantId: number
): Promise<any> => {
  const { data } = await httpClient.post(
    `/b-client/v1/project/${projectId}/participant/${participantId}/resend-invitation`,
    null,
    {
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

const deleteTestParticipant = async (
  projectId: number,
  testId: number,
  participantId: number
): Promise<any> => {
  await httpClient.delete(
    `/b-client/v1/project/${projectId}/participant/${participantId}`,
    {
      headers: authHeader() as AxiosRequestHeaders,
      data: {
        bundle_id: testId,
      },
    }
  );
};

const getProjectTestReport = async (
  projectId: number,
  bundleId: number
): Promise<any> => {
  const { data } = await httpClient.get(
    `/b-client/v1/project/${projectId}/bundle/${bundleId}/report`,
    {
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

const getProjectTestResult = async (
  projectId: number,
  bundleId: number,
  participantId: number
): Promise<any> => {
  const { data } = await httpClient.get(
    `/b-client/v1/project/${projectId}/bundle/${bundleId}/participant/${participantId}/download`,
    {
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

const getProjectReport = async (projectId: number): Promise<any> => {
  const { data } = await httpClient.get(
    `/b-client/v1/project/${projectId}/report`,
    {
      headers: authHeader() as AxiosRequestHeaders,
    }
  );

  return data;
};

export default {
  getProjects,
  getProject,
  getProjectParticipant,
  createProject,
  updateProjectStatus,
  validateProject,
  inviteParticipants,
  getProjectTestDetail,
  getProjectParticipantDetail,
  getProjectTestReport,
  getProjectTestResult,
  resendEmailProjectParticipant,
  deleteTestParticipant,
  getProjectReport,
};
