
import { computed, defineComponent, ref, watch } from "vue";

// interfaces
import { IAssessment } from "@/interfaces/assessment";

export default defineComponent({
  props: {
    data: {
      type: Array,
      required: true,
    },
    selectedAssessment: {
      type: Array,
      required: true,
    },
  },
  emits: ["setSelectedAssessment"],
  setup(props, { emit }) {
    const selectAssessment = (id: number) => {
      const indexOfId = props.selectedAssessment.indexOf(id);
      const selectedAssessmentCopy = [...props.selectedAssessment];

      if (indexOfId > -1) selectedAssessmentCopy.splice(indexOfId, 1);
      else selectedAssessmentCopy.push(id);

      emit("setSelectedAssessment", selectedAssessmentCopy);
    };
    const isAssessmentChosen = (id: number) =>
      props.selectedAssessment.includes(id);

    // filtered assessments
    const filterAssessment = ref<string>("");
    const filteredAssessments = computed(() => {
      return (props.data as IAssessment[]).filter(({ name }) =>
        name.toLowerCase().includes(filterAssessment.value.toLowerCase())
      );
    });

    // check all assessments
    const checkAll = ref<boolean>(false);
    watch(checkAll, (val) => {
      let selectedAssessmentCopy = [...props.selectedAssessment];

      if (val)
        selectedAssessmentCopy = (props.data as IAssessment[]).map(
          ({ id }) => id
        );
      else selectedAssessmentCopy = [];

      emit("setSelectedAssessment", selectedAssessmentCopy);
    });

    return {
      selectAssessment,
      isAssessmentChosen,
      filterAssessment,
      filteredAssessments,
      checkAll,
    };
  },
});
