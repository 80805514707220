
import {
  defineComponent,
  ref,
  inject,
  onMounted,
  computed,
  reactive,
} from "vue";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";

import { AxiosError } from "axios";

// components
import AssessmentList from "@/components/molecules/AssessmentList.molecules.vue";

// services
import projectService from "@/services/project.service";

// interfaces
import { ITest } from "@/interfaces/test";
import { ProjectStatusEnum } from "@/interfaces/project";
import InputLabel from "@/components/atoms/InputLabel.vue";

interface IProjectDetail {
  name: string;
  started_at: string;
  ended_at: string;
}

export default defineComponent({
  components: {
    AssessmentList,
    InputLabel,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const setPageTitle = inject("setPageTitle") as CallableFunction;
    onMounted(() => setPageTitle("Tambah Peserta"));

    const assessments = ref<ITest[]>([]);
    const projectDetail = reactive<IProjectDetail>({} as IProjectDetail);
    onMounted(async () => {
      const projectId = Number(route.params.projectId);

      const { data } = await projectService.getProject(projectId);
      if (data.status === ProjectStatusEnum.Draft)
        router.push(`/admin/project/${projectId}`);

      projectDetail.name = data.name;
      projectDetail.started_at = moment(data.started_at).format(
        "DD MMMM YYYY, [00:00]"
      );
      projectDetail.ended_at = moment(data.ended_at).format(
        "DD MMMM YYYY, [23:59]"
      );
      assessments.value = data.bundles;
    });

    const goBack = () => {
      router.go(-1);
    };

    // select assessment
    const selectedAssessment = ref<number[]>([]);
    const setSelectedAssessment = (val: number[]) => {
      selectedAssessment.value = val;
    };

    // email
    const emails = ref<string[]>([]);
    const emailInput = ref<string>("");
    const isEmailValid = (email: string): boolean => {
      const emailRegex = /\S+@\S+\.\S+/;
      return emailRegex.test(email);
    };
    const insertEmail = () => {
      const trimmedEmail = emailInput.value.replace(/\s/g, "");
      if (!emails.value.includes(trimmedEmail) && isEmailValid(trimmedEmail)) {
        emails.value.push(trimmedEmail);
        emailInput.value = "";
      } else {
        emailInput.value = trimmedEmail;
      }
    };
    const pasteEmail = (evt: any) => {
      const emailVal: string = evt.clipboardData
        .getData("text")
        .replace(/[\n\t]/g, "|")
        .split("|")
        .map((d: string) => d.replace(/\s/g, ""))
        .filter(
          (d: string) => d && !emails.value.includes(d) && isEmailValid(d)
        );

      const uniqueEmails = new Set([...emails.value, ...emailVal]);
      emails.value = [...uniqueEmails];

      setTimeout(() => {
        emailInput.value = "";
      }, 0);
    };
    const removeEmail = (idx: number) => {
      emails.value.splice(idx, 1);
    };
    const removeEmailOnDelete = () => {
      if (!emailInput.value) removeEmail(emails.value.length - 1);
    };

    // endedAt
    const endedAt = ref<string>("");

    // setter by queries
    onMounted(() => {
      if (route.query.emails && route.query.emails.length) {
        emails.value = Array.isArray(route.query.emails)
          ? (route.query.emails as string[])
          : [route.query.emails];
      }

      if (route.query.ended_at) {
        endedAt.value = route.query.ended_at as string;
      }

      if (route.query.test_id && route.query.test_id.length) {
        selectedAssessment.value = Array.isArray(route.query.test_id)
          ? (route.query.test_id as string[]).map((d) => +d)
          : [Number(route.query.test_id)];
      }
    });

    // pay handler
    const payInvitation = async () => {
      try {
        const reqQueries = {
          emails: emails.value,
          ended_at: endedAt.value,
          bundle_id: selectedAssessment.value,
        };
        const projectId = Number(route.params.projectId);
        await projectService.validateProject(projectId, reqQueries);

        router.push({
          path: `/admin/project/${projectId}/invite/confirmation`,
          query: reqQueries,
        });
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      }
    };

    const emailInputRef = ref<null | { focus: () => null }>(null);
    const focusOnEmailInput = () => {
      if (emailInputRef.value) emailInputRef.value.focus();
    };

    const canSubmit = computed(
      () =>
        emails.value.length && endedAt.value && selectedAssessment.value.length
    );

    return {
      assessments,
      projectDetail,
      emailInput,
      emailInputRef,
      focusOnEmailInput,
      emails,
      endedAt,
      insertEmail,
      pasteEmail,
      removeEmail,
      removeEmailOnDelete,
      setSelectedAssessment,
      selectedAssessment,
      payInvitation,
      goBack,
      canSubmit,
    };
  },
});
