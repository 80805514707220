import { IParticipant } from "./participant";
import { ITest } from "./test";

export enum ProjectStatusEnum {
  Draft = "Draft",
  NotStarted = "Not Started",
  OnGoing = "On Going",
  Done = "Done",
}

type ProjectStatusType =
  | ProjectStatusEnum.Draft
  | ProjectStatusEnum.NotStarted
  | ProjectStatusEnum.OnGoing
  | ProjectStatusEnum.Done;

export interface IProject {
  id: number;
  company_id: number;
  name: string;
  started_at: string;
  ended_at: string;
  status: ProjectStatusType;
  status_label: string;
  created_at?: string;
}

export interface IProjectDetail extends IProject {
  participants: IParticipant[];
  bundles: ITest[];
}
