<template>
  <label
    class="
      block
      mb-2
      text-sm
      leading-6
      font-semibold
      tracking-wider
      text-blue-900
    "
  >
    {{ label }}<span class="text-red-700" v-if="isRequired">*</span>
  </label>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    label: String,
    isRequired: {
      type: Boolean,
      default: () => false,
    },
  },
});
</script>
